@import url(https://fonts.googleapis.com/css?family=Assistant:400,600,700&display=swap);
html {
  height: 100%;
}

body {
  background-color: #000000 !important;
  /* background-image: linear-gradient(#021b40, #0a2351, #193170); */
  /* background:#2E3540; */
  font-family: "Assistant", sans-serif, -apple-system, BlinkMacSystemFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 1.8rem !important;
  font-weight: 700;
  color: #ecf3fb;
}

h1 {
  font-size: 2.2rem !important;
  font-weight: 700 !important;
  color: #ecf3fb !important;
}

.modal-title {
  font-size: 1.3rem !important;
  font-weight: 700;
  color: #ecf3fb !important;
}

.modal-title span {
  font-weight: 400;
}

.modal-title.h4 {
  font-weight: 500;
}

.modal-title p {
  font-size: 16px;
}

.modal-title strong {
  word-break: break-all;
}

.modal-content {
  background: #111111 !important;
}

.modal-header {
  border-bottom: 1px solid #dee2e60e !important;
}

.modal-footer {
  border-top: 1px solid #dee2e60e !important;
}

.close {
  color: #ecf3fb !important;
}

strong {
  color: #ecf3fb;
  font-weight: 600 !important;
}

h6 {
  color: #ecf3fb;
}

h4 {
  color: #ecf3fb;
  font-weight: 600;
}

th {
  height: 45px;
  font-size: 14px;
  font-weight: 100;
  color: #ecf3fb;
  border: 0px solid #111111;
}

.table td,
.table th {
  padding: 0.6rem !important;
  vertical-align: baseline !important;
  border-top: 1px solid #dee2e6;
  text-align: left;
}

td {
  color: #ecf3fb;
  border: 0px solid #111111;
}

.table td,
.table th {
  border-bottom: 1px solid #111111 !important;
}
.table td,
.table th {
  border-top: 0px solid #111111 !important;
}

.table td,
.table th {
  border-left: 0px solid #111111 !important;
}

.table td,
.table th {
  border-right: 0px solid #111111 !important;
}

.table-responsive {
  overflow: hidden !important;
}

.badge-primary {
  color: #060f1e !important;
  background-color: #00dae7 !important;
}

.table-striped td {
  padding: 15px 10px !important;
}

label {
  color: #ecf3fb;
  font-size: 14px;
}

/* svg {
  overflow: hidden;
} */

.dropdown {
  margin: 10px !important;
}

.dropdown-menu {
  display: block;
  width: 300 !important;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: #ecf3fb;
}

.form-control {
  height: 45px !important;
  color: white !important;
  margin: auto;
  max-width: 300px;
  border: 1px solid #ffffff !important;
  background-color: transparent !important;
}

.lufs-input input{
  height: 31px!important;
}


.form-control::-webkit-input-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Microsoft Edge */

#downshift-0-input {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  margin: auto !important;
  max-width: 360px !important;
}

input[aria-autocomplete="list"] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: auto;
  max-width: 360px;
}

.spinner-border-sm {
  /* border-width: 0.1em; */
}

.auto-complete {
  cursor: pointer;
  margin: auto;
  padding: 10px;
  max-width: 360px;
  background: white;
  color: #495057;
  border-bottom: 1px solid #f3f3f3;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #039be5;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.buttonExternal {
  margin-top: 10px;
}

.buttonExternal:hover {
  color: #212529 !important;
  opacity: 1 !important;
}

.google-button {
  margin: 0 !important;
  cursor: pointer;
  outline: 0;
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}

.google-button:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

.google-button-container {
  display: inline-flex;
  vertical-align: baseline;
}

button ~ button {
  margin-left: 0px;
}

.btn {
  font-weight: 600 !important;
}

.btn:hover {
  background-color: 0.9;
}

small {
  color: #ecf3fb;
  font-size: 11px;
}

.form {
  text-align: center;
}

.dd-wrapper {
  font-size: 1.6rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: unset !important;
  margin: auto !important;
  width: 400px !important;
}
.dd-wrapper .dd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 38px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  cursor: default;
  position: relative;
  background-color: #fff;
}
.dd-wrapper .dd-header span {
  margin-right: 20px;
}
.dd-wrapper .dd-header-title {
  font-weight: 300;
  margin: 2px 20px;
  margin-right: 30px;
}
.dd-wrapper .angle-down {
  color: #000;
  margin-right: 20px;
}
.dd-wrapper .dd-list {
  z-index: 10;
  position: absolute;
  width: 400px !important;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  font-weight: 500;
  padding: 15px 0;
  max-height: 215px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.dd-wrapper .dd-list-item {
  width: 100%;
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: default;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dd-wrapper .dd-list-item.selected {
  color: #fff;
  background-color: #274ca0 !important;
}
.dd-wrapper .dd-list-item:hover {
  color: #fff;
  background-color: #274ca0 !important;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader {
  margin: 2px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid #00dae8;
  border-right: 0.2em solid #00dae8;
  border-bottom: 0.2em solid #00dae8;
  border-left: 0.2em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6 !important;
}

.pia {
  border-left: 1px transparent !important;
  border-right: 2px transparent !important;
}

.btn-primary {
  color: #060f1e !important;
  background-color: #00dae7 !important;
  border-color: #00dae7 !important;
}

.btn-dark {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-outline {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
}

.btn-light {
  color: #fff !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

a {
  color: #ecf3fb !important;
  font-weight: 600;
}

a:hover {
  opacity: 0.9;
}

.lds-roller div:after {
  background: #00dae7;
}

.btn-group-lg > .btn,
.btn-lg {
  width: 100%;
  max-width: 300px;
}

.alert-danger .alert-link {
  color: #cb3e21 !important;
}

.alert-info .alert-link {
  color: black !important;
}

.alert-success .alert-link {
  color: rgb(38, 59, 44) !important;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 1.25rem;
  color: black !important;
}

strong,
b {
  font-weight: bold;
}

.homeText {
  color: #ecf3fb;
  margin: 20px;
  font-size: 28px;
  font-weight: 700;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#g-recaptcha div {
  margin: 0 auto;
}

.App-logo {
  height: 50px;
  margin: 0 20px 20px;
}

.dropdown {
  cursor: pointer;
  margin: 0px !important;
}

.dropdown:focus {
  outline: 0;
}

.downloadIcon {
  opacity: 0.7;
  float: right;
  padding-top: 12px;
  margin-right: 5px;
}

.downloadIcon:hover {
  opacity: 1;
}

.ant-slider-vertical {
  margin-left: 0 !important;
}

.mixerContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 600px;
}
.mixerContainer > * {
  margin-left: auto;
  margin-right: auto;
}

.about {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #252a2e;
}

.logout {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  cursor: pointer;
}

.logout strong {
  margin-left: 2px;
  color: #4e5265;
}

.logout strong:hover {
  color: #d0d7e0;
}

a {
  color: #274ca0;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: rgb(48, 92, 196);
}

.btn a {
  color: white;
  text-decoration: none;
}

.btn-link:hover {
  color: white !important;
}

.separator {
  color: silver;
}

.playerContainer {
  max-width: 450px;
  margin: 20px auto 0px;
}

.playerProgress {
  margin-top: 8px;
}

.sb-soundplayer-btn {
  display: inline-block;
  background-color: transparent;
  color: #ecf3fb !important;
  font-size: 16px !important;
}

.sb-soundplayer-progress-container {
  background-color: white !important;
}

.trackName {
  margin-left: 8px;
  font-size: 24px;
  color: #ecf3fb;
  font-weight: 400;
}

.footer {
  letter-spacing: 1px;
  color: silver;
  margin: 40px auto;
}

.footer a {
  color: grey;
}

.actions {
  display: inline-flex;
}

.actions button {
  margin: 30px 5px;
}

.alert-light {
  line-height: 1.5;
  border: 1px solid #111111;
  color: #fff;
  background-color: #111111;
}

.alert-light button {
  margin-top: 10px;
}

.advancedButton {
  margin-top: 5px;
  padding: 0;
  background-color: transparent;
  color: #00dae8;
  border: none !important;
}

.advancedButton:hover {
  background-color: transparent;
  color: #00dae8;
  opacity: 0.98;
  border: none;
}

.advancedButton:active {
  background-color: white !important;
  color: #00dae8;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.advancedButton:focus {
  background-color: transparent;
  color: #00dae8;
  border: none !important;
  outline: none;
  box-shadow: none;
}

.advancedButton:focus-within {
  background-color: transparent;
  color: #00dae8;
  border: none !important;
  outline: none;
  box-shadow: none;
}

.band-example {
  margin-top: 20px;
  font-size: 14px;
}

h2 {
  font-weight: 700;
}

.grey {
  background-color: silver !important;
}

.grey button:hover {
  background-color: #274ca0;
}

.tasksTitle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.tasksTitle button {
  margin-left: auto;
}

.searchNew {
  margin-left: auto;
}

.searchNew input {
  margin-right: 10px;
  width: 200px;
  color: #fff !important;
  border-color: #4e5265 !important;
  background-color: transparent;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e5265 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e5265 !important;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e5265 !important;
  opacity: 1; /* Firefox */
}

.searchNew input::-webkit-input-placeholder {
  color: pink;
}

.searchNew input:-ms-input-placeholder {
  color: pink;
}

.searchNew input::placeholder {
  color: pink;
}

.searchNew input:active {
  border-color: #4e5265 !important;
  background-color: transparent;
}
.searchNew input:focus {
  border-color: #4e5265 !important;
  background-color: transparent;
}

.filename {
  margin: 2px;
  color: silver;
  opacity: 0.7;
}

.yt-container {
  display: flex;
  flex-flow: wrap;
  align-content: center;
  margin: auto;
  padding: 0 !important;
}

.yt-container-link {
  font-weight: 600;
  position: relative;
  top: -3px;
}

.yt-item {
  padding: 14px;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.yt-item img {
  margin-right: 10px;
  object-fit: contain;
}

.btn-outline-light:disabled {
  opacity: 0.2 !important;
}

.btn-lg {
  height: 45px;
  font-size: 16px;
}

.btn-outline-light {
  color: #000000;
  border-color: #f8f9fa;
  background-color: white;
  width: 300px;
  height: 45px;
}

.yt-item:nth-child(odd) {
  background-color: #111111;
}

@media screen and (max-width: 990px) {
  .hide-custom {
    display: none;
  }
  .row {
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width: 680px) {
  .hide-mobile {
    display: none;
  }

  .playerContainer {
    max-width: 300px;
  }

  .player {
    font-size: 12px;
  }

  .trackName {
    margin-left: 5px;
    font-size: 22px;
  }
}

@media screen and (max-width: 320px) {
  .hide-micro-mobile {
    display: none;
  }
}

.title {
  display: inline-flex;
  align-items: center;
}

.progress {
  background-color: transparent;
  margin-left: 20px;
  max-width: 100%;
}

.player {
  max-width: 500px;
  text-align: left;
  margin: 5px auto 10px auto;
}

header {
  display: flex;
  justify-content: center;
}

.App-header {
  margin-top: 30px;
}

.App {
  text-align: center;
}

.form {
  display: flex;
  flex-wrap: nowrap;
}

section.pricing {
  background: transparent;
  padding: 10px !important;
}

.pricing .card {
  /* padding: 20px; */
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card {
  background: #1e233a;
}

.pricing hr {
  margin: 1rem 0;
  background-color: black;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.fa-ul {
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important;
}

.pricing ul li {
  margin: 1.5rem;
  list-style-type: none;
}

.pricing .text-muted {
  color: #04dae8 !important;
}

.break-line {
  max-width: 600px;
  opacity: 0.85;
  margin: 0 auto;
  word-break: break-all;
}

.react-confirm-alert-body > h1 {
  color: #333 !important;
}

.react-confirm-alert-button-group > button {
  margin-left: 5px;
  font-weight: 700;
  color: #fff !important;
  background-color: #060f1e !important;
}

.react-confirm-alert-button-group > button:nth-child(2) {
  margin-left: 5px;
  font-weight: 700;
  color: #060f1e !important;
  border: solid 1px #a8b2c1 !important;
  background-color: transparent !important;
}

.react-confirm-alert-body > h1 {
  color: #060f1e !important;
}

/* .fa-li {
  color: white;
} */

/* .pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
} */

.no-link-color {
  color: #252a2e;
}

.no-link-color:hover {
  color: #252a2e;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: rgb(175, 175, 175);
  cursor: pointer;
}

.buttonLink:hover {
  text-decoration: underline;
}

.advancedButton:active {
  background: transparent !important;
}

.trTasksRight {
  font-size: 14px;
  font-weight: 100;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-color: #111111;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  height: 50px;
  justify-content: left;
  position: relative;
  transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    background-color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    fill 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  width: 100%;
}

.trTasksLeft {
  font-size: 14px;
  font-weight: 100;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-color: #111111;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  height: 50px;
  justify-content: left;
  position: relative;
  transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    background-color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    fill 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.separator {
  font-size: 12px;
  width: 100%;
  max-width: 745px;
  text-align: center;
  border-bottom: 1px solid black;
  line-height: 0.1em;
  margin: 10px auto 20px auto;
  text-align: center;
}

.separator span {
  background: transparent;
  padding: 0 10px;
}

.social-fb {
  color: #1877f2;
}

.social-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.social-tw {
  color: #1da1f2;
}

.wechatButton {
  background-color: #02a900;
  border-radius: 4px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  height: 55px;
  margin-bottom: 0;
  max-height: 55px;
  min-height: 40px;
  outline: none;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  width: 100%;
  max-width: 755px;
}

.wechatButton:hover {
  background-color: #028b00;
}

.modal.custom-modal .modal-dialog {
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999999;
}

.modal-app-content {
  padding: 12px 24px;
  background: linear-gradient(89.98deg, #00dae8 7.78%, #4b93ff 98.87%);
  display: flex;
}

.modal-footer-custom {
  padding: 7px 20px;
  background: linear-gradient(89.98deg, #45c5d1 7.78%, #4483e6 98.87%);
}

.app-title {
  color: black;
  margin-top: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.app-logo {
  border-radius: 15px;
  margin-right: 12px;
}

.app-button-continue {
  text-decoration: underline;
  background-color: transparent !important;
  color: black;
  border: none;
}

.app-open {
  padding: 12px 32px;
  border: none;
  background-color: white !important;
}
/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}

.sb-soundplayer-progress-inner {
  background-color: #00dae8 !important;
}

.cross:hover {
  fill: #b43844;
}

.react-confirm-alert-body h1 {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.react-confirm-alert-body {
  padding: 40px !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.dropdown-menu {
  background: #111111 !important;
}

.dropdown-item {
  line-height: 1.5;
  color: #ecf3fb !important;
}

.dropdown-item:active {
  background: #060f1e !important;
}

.dropdown-item:hover {
  background: #1b1b1b !important;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: silver !important;
}

#outside {
  border: 1px solid black;
  height: 20px;
  background-color: silver;
  width: 500px;
}
#inside {
  width: 0px;
  height: 100%;
  background-color: firebrick;
  overflow: visible;
  color: white;
  white-space: nowrap;
}

input[type="checkbox"] {
  opacity: 1 !important;
}

.featured:hover > a {
  color: #00dae8 !important;
}

.header-item:hover a {
  color: #00dae8 !important;
}

.header-item-selected {
  color: #00dae8;
  text-decoration: underline;
  -webkit-text-decoration-color: #00dae8;
          text-decoration-color: #00dae8;
  text-decoration-thickness: 1px;
  text-underline-offset: 18px;
}

.header-item-selected > a {
  color: #00dae8 !important;
}

.modal-body .description {
  font-size: 16px;
  line-height: 1.5;
}

.page {
  margin-top: 30px;
  line-height: 1.5;
}

.page p {
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: #ecf3fb;
}

.page .description-small {
  font-size: 12px;
}

.title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 1.8rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
.subtitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.label-paragraph {
  line-height: 1.5;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 16px;
}

.skeleton-tasks {
  margin: 30px;
}

.hide-content-tasks {
  display: none;
}

.title-task {
  line-height: 1.5;
  word-break: break-all;
}

.header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 30px;
 }

 .footer-wrap {
    min-height: 100%;
    height: auto !important; 
    height: 100%; 
    margin: 0 auto -30px;
 }
 .footer{
    height:30px;
    text-align: center;
 }

 .footer a{
     margin: 10px;
 }

 .btn-light.focus, .btn-light:focus {
    color: #212529;
    background-color: transparent!important;
    border-color: transparent!important;
    box-shadow: 0 0 0 0.2rem rgba(216,217,219,0)!important;
}
