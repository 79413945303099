.header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 30px;
 }

 .footer-wrap {
    min-height: 100%;
    height: auto !important; 
    height: 100%; 
    margin: 0 auto -30px;
 }
 .footer{
    height:30px;
    text-align: center;
 }

 .footer a{
     margin: 10px;
 }

 .btn-light.focus, .btn-light:focus {
    color: #212529;
    background-color: transparent!important;
    border-color: transparent!important;
    box-shadow: 0 0 0 0.2rem rgba(216,217,219,0)!important;
}