strong,
b {
  font-weight: bold;
}

.homeText {
  color: #ecf3fb;
  margin: 20px;
  font-size: 28px;
  font-weight: 700;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#g-recaptcha div {
  margin: 0 auto;
}

.App-logo {
  height: 50px;
  margin: 0 20px 20px;
}

.dropdown {
  cursor: pointer;
  margin: 0px !important;
}

.dropdown:focus {
  outline: 0;
}

.downloadIcon {
  opacity: 0.7;
  float: right;
  padding-top: 12px;
  margin-right: 5px;
}

.downloadIcon:hover {
  opacity: 1;
}

.ant-slider-vertical {
  margin-left: 0 !important;
}

.mixerContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 600px;
}
.mixerContainer > * {
  margin-left: auto;
  margin-right: auto;
}

.about {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #252a2e;
}

.logout {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  cursor: pointer;
}

.logout strong {
  margin-left: 2px;
  color: #4e5265;
}

.logout strong:hover {
  color: #d0d7e0;
}

a {
  color: #274ca0;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: rgb(48, 92, 196);
}

.btn a {
  color: white;
  text-decoration: none;
}

.btn-link:hover {
  color: white !important;
}

.separator {
  color: silver;
}

.playerContainer {
  max-width: 450px;
  margin: 20px auto 0px;
}

.playerProgress {
  margin-top: 8px;
}

.sb-soundplayer-btn {
  display: inline-block;
  background-color: transparent;
  color: #ecf3fb !important;
  font-size: 16px !important;
}

.sb-soundplayer-progress-container {
  background-color: white !important;
}

.trackName {
  margin-left: 8px;
  font-size: 24px;
  color: #ecf3fb;
  font-weight: 400;
}

.footer {
  letter-spacing: 1px;
  color: silver;
  margin: 40px auto;
}

.footer a {
  color: grey;
}

.actions {
  display: inline-flex;
}

.actions button {
  margin: 30px 5px;
}

.alert-light {
  line-height: 1.5;
  border: 1px solid #111111;
  color: #fff;
  background-color: #111111;
}

.alert-light button {
  margin-top: 10px;
}

.advancedButton {
  margin-top: 5px;
  padding: 0;
  background-color: transparent;
  color: #00dae8;
  border: none !important;
}

.advancedButton:hover {
  background-color: transparent;
  color: #00dae8;
  opacity: 0.98;
  border: none;
}

.advancedButton:active {
  background-color: white !important;
  color: #00dae8;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.advancedButton:focus {
  background-color: transparent;
  color: #00dae8;
  border: none !important;
  outline: none;
  box-shadow: none;
}

.advancedButton:focus-within {
  background-color: transparent;
  color: #00dae8;
  border: none !important;
  outline: none;
  box-shadow: none;
}

.band-example {
  margin-top: 20px;
  font-size: 14px;
}

h2 {
  font-weight: 700;
}

.grey {
  background-color: silver !important;
}

.grey button:hover {
  background-color: #274ca0;
}

.tasksTitle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.tasksTitle button {
  margin-left: auto;
}

.searchNew {
  margin-left: auto;
}

.searchNew input {
  margin-right: 10px;
  width: 200px;
  color: #fff !important;
  border-color: #4e5265 !important;
  background-color: transparent;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e5265 !important;
  opacity: 1; /* Firefox */
}

.searchNew input::placeholder {
  color: pink;
}

.searchNew input:active {
  border-color: #4e5265 !important;
  background-color: transparent;
}
.searchNew input:focus {
  border-color: #4e5265 !important;
  background-color: transparent;
}

.filename {
  margin: 2px;
  color: silver;
  opacity: 0.7;
}

.yt-container {
  display: flex;
  flex-flow: wrap;
  align-content: center;
  margin: auto;
  padding: 0 !important;
}

.yt-container-link {
  font-weight: 600;
  position: relative;
  top: -3px;
}

.yt-item {
  padding: 14px;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.yt-item img {
  margin-right: 10px;
  object-fit: contain;
}

.btn-outline-light:disabled {
  opacity: 0.2 !important;
}

.btn-lg {
  height: 45px;
  font-size: 16px;
}

.btn-outline-light {
  color: #000000;
  border-color: #f8f9fa;
  background-color: white;
  width: 300px;
  height: 45px;
}

.yt-item:nth-child(odd) {
  background-color: #111111;
}

@media screen and (max-width: 990px) {
  .hide-custom {
    display: none;
  }
  .row {
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width: 680px) {
  .hide-mobile {
    display: none;
  }

  .playerContainer {
    max-width: 300px;
  }

  .player {
    font-size: 12px;
  }

  .trackName {
    margin-left: 5px;
    font-size: 22px;
  }
}

@media screen and (max-width: 320px) {
  .hide-micro-mobile {
    display: none;
  }
}

.title {
  display: inline-flex;
  align-items: center;
}

.progress {
  background-color: transparent;
  margin-left: 20px;
  max-width: 100%;
}

.player {
  max-width: 500px;
  text-align: left;
  margin: 5px auto 10px auto;
}

header {
  display: flex;
  justify-content: center;
}

.App-header {
  margin-top: 30px;
}

.App {
  text-align: center;
}

.form {
  display: flex;
  flex-wrap: nowrap;
}

section.pricing {
  background: transparent;
  padding: 10px !important;
}

.pricing .card {
  /* padding: 20px; */
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card {
  background: #1e233a;
}

.pricing hr {
  margin: 1rem 0;
  background-color: black;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.fa-ul {
  padding-inline-start: 0px !important;
}

.pricing ul li {
  margin: 1.5rem;
  list-style-type: none;
}

.pricing .text-muted {
  color: #04dae8 !important;
}

.break-line {
  max-width: 600px;
  opacity: 0.85;
  margin: 0 auto;
  word-break: break-all;
}

.react-confirm-alert-body > h1 {
  color: #333 !important;
}

.react-confirm-alert-button-group > button {
  margin-left: 5px;
  font-weight: 700;
  color: #fff !important;
  background-color: #060f1e !important;
}

.react-confirm-alert-button-group > button:nth-child(2) {
  margin-left: 5px;
  font-weight: 700;
  color: #060f1e !important;
  border: solid 1px #a8b2c1 !important;
  background-color: transparent !important;
}

.react-confirm-alert-body > h1 {
  color: #060f1e !important;
}

/* .fa-li {
  color: white;
} */

/* .pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
} */

.no-link-color {
  color: #252a2e;
}

.no-link-color:hover {
  color: #252a2e;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: rgb(175, 175, 175);
  cursor: pointer;
}

.buttonLink:hover {
  text-decoration: underline;
}

.advancedButton:active {
  background: transparent !important;
}

.trTasksRight {
  font-size: 14px;
  font-weight: 100;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-color: #111111;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  height: 50px;
  justify-content: left;
  position: relative;
  transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    background-color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    fill 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  width: 100%;
}

.trTasksLeft {
  font-size: 14px;
  font-weight: 100;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-color: #111111;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  height: 50px;
  justify-content: left;
  position: relative;
  transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    background-color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),
    fill 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.separator {
  font-size: 12px;
  width: 100%;
  max-width: 745px;
  text-align: center;
  border-bottom: 1px solid black;
  line-height: 0.1em;
  margin: 10px auto 20px auto;
  text-align: center;
}

.separator span {
  background: transparent;
  padding: 0 10px;
}

.social-fb {
  color: #1877f2;
}

.social-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.social-tw {
  color: #1da1f2;
}

.wechatButton {
  background-color: #02a900;
  border-radius: 4px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  height: 55px;
  margin-bottom: 0;
  max-height: 55px;
  min-height: 40px;
  outline: none;
  overflow: hidden;
  position: relative;
  user-select: none;
  vertical-align: top;
  width: 100%;
  max-width: 755px;
}

.wechatButton:hover {
  background-color: #028b00;
}

.modal.custom-modal .modal-dialog {
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999999;
}

.modal-app-content {
  padding: 12px 24px;
  background: linear-gradient(89.98deg, #00dae8 7.78%, #4b93ff 98.87%);
  display: flex;
}

.modal-footer-custom {
  padding: 7px 20px;
  background: linear-gradient(89.98deg, #45c5d1 7.78%, #4483e6 98.87%);
}

.app-title {
  color: black;
  margin-top: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.app-logo {
  border-radius: 15px;
  margin-right: 12px;
}

.app-button-continue {
  text-decoration: underline;
  background-color: transparent !important;
  color: black;
  border: none;
}

.app-open {
  padding: 12px 32px;
  border: none;
  background-color: white !important;
}
/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}

.sb-soundplayer-progress-inner {
  background-color: #00dae8 !important;
}

.cross:hover {
  fill: #b43844;
}

.react-confirm-alert-body h1 {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.react-confirm-alert-body {
  padding: 40px !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.dropdown-menu {
  background: #111111 !important;
}

.dropdown-item {
  line-height: 1.5;
  color: #ecf3fb !important;
}

.dropdown-item:active {
  background: #060f1e !important;
}

.dropdown-item:hover {
  background: #1b1b1b !important;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: silver !important;
}

#outside {
  border: 1px solid black;
  height: 20px;
  background-color: silver;
  width: 500px;
}
#inside {
  width: 0px;
  height: 100%;
  background-color: firebrick;
  overflow: visible;
  color: white;
  white-space: nowrap;
}

input[type="checkbox"] {
  opacity: 1 !important;
}

.featured:hover > a {
  color: #00dae8 !important;
}

.header-item:hover a {
  color: #00dae8 !important;
}

.header-item-selected {
  color: #00dae8;
  text-decoration: underline;
  text-decoration-color: #00dae8;
  text-decoration-thickness: 1px;
  text-underline-offset: 18px;
}

.header-item-selected > a {
  color: #00dae8 !important;
}

.modal-body .description {
  font-size: 16px;
  line-height: 1.5;
}

.page {
  margin-top: 30px;
  line-height: 1.5;
}

.page p {
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: #ecf3fb;
}

.page .description-small {
  font-size: 12px;
}

.title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 1.8rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
.subtitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.label-paragraph {
  line-height: 1.5;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 16px;
}

.skeleton-tasks {
  margin: 30px;
}

.hide-content-tasks {
  display: none;
}

.title-task {
  line-height: 1.5;
  word-break: break-all;
}
