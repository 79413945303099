@import url("https://fonts.googleapis.com/css?family=Assistant:400,600,700&display=swap");

html {
  height: 100%;
}

body {
  background-color: #000000 !important;
  /* background-image: linear-gradient(#021b40, #0a2351, #193170); */
  /* background:#2E3540; */
  font-family: "Assistant", sans-serif, -apple-system, BlinkMacSystemFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 1.8rem !important;
  font-weight: 700;
  color: #ecf3fb;
}

h1 {
  font-size: 2.2rem !important;
  font-weight: 700 !important;
  color: #ecf3fb !important;
}

.modal-title {
  font-size: 1.3rem !important;
  font-weight: 700;
  color: #ecf3fb !important;
}

.modal-title span {
  font-weight: 400;
}

.modal-title.h4 {
  font-weight: 500;
}

.modal-title p {
  font-size: 16px;
}

.modal-title strong {
  word-break: break-all;
}

.modal-content {
  background: #111111 !important;
}

.modal-header {
  border-bottom: 1px solid #dee2e60e !important;
}

.modal-footer {
  border-top: 1px solid #dee2e60e !important;
}

.close {
  color: #ecf3fb !important;
}

strong {
  color: #ecf3fb;
  font-weight: 600 !important;
}

h6 {
  color: #ecf3fb;
}

h4 {
  color: #ecf3fb;
  font-weight: 600;
}

th {
  height: 45px;
  font-size: 14px;
  font-weight: 100;
  color: #ecf3fb;
  border: 0px solid #111111;
}

.table td,
.table th {
  padding: 0.6rem !important;
  vertical-align: baseline !important;
  border-top: 1px solid #dee2e6;
  text-align: left;
}

td {
  color: #ecf3fb;
  border: 0px solid #111111;
}

.table td,
.table th {
  border-bottom: 1px solid #111111 !important;
}
.table td,
.table th {
  border-top: 0px solid #111111 !important;
}

.table td,
.table th {
  border-left: 0px solid #111111 !important;
}

.table td,
.table th {
  border-right: 0px solid #111111 !important;
}

.table-responsive {
  overflow: hidden !important;
}

.badge-primary {
  color: #060f1e !important;
  background-color: #00dae7 !important;
}

.table-striped td {
  padding: 15px 10px !important;
}

label {
  color: #ecf3fb;
  font-size: 14px;
}

/* svg {
  overflow: hidden;
} */

.dropdown {
  margin: 10px !important;
}

.dropdown-menu {
  display: block;
  width: 300 !important;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: #ecf3fb;
}

.form-control {
  height: 45px !important;
  color: white !important;
  margin: auto;
  max-width: 300px;
  border: 1px solid #ffffff !important;
  background-color: transparent !important;
}

.lufs-input input{
  height: 31px!important;
}


.form-control::-webkit-input-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: white !important;
  opacity: 0.5 !important;
} /* Microsoft Edge */

#downshift-0-input {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  margin: auto !important;
  max-width: 360px !important;
}

input[aria-autocomplete="list"] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: auto;
  max-width: 360px;
}

.spinner-border-sm {
  /* border-width: 0.1em; */
}

.auto-complete {
  cursor: pointer;
  margin: auto;
  padding: 10px;
  max-width: 360px;
  background: white;
  color: #495057;
  border-bottom: 1px solid #f3f3f3;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #039be5;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buttonExternal {
  margin-top: 10px;
}

.buttonExternal:hover {
  color: #212529 !important;
  opacity: 1 !important;
}

.google-button {
  margin: 0 !important;
  cursor: pointer;
  outline: 0;
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}

.google-button:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

.google-button-container {
  display: inline-flex;
  vertical-align: baseline;
}

button ~ button {
  margin-left: 0px;
}

.btn {
  font-weight: 600 !important;
}

.btn:hover {
  background-color: 0.9;
}

small {
  color: #ecf3fb;
  font-size: 11px;
}

.form {
  text-align: center;
}

.dd-wrapper {
  font-size: 1.6rem;
  user-select: none;
  position: unset !important;
  margin: auto !important;
  width: 400px !important;
}
.dd-wrapper .dd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 38px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  cursor: default;
  position: relative;
  background-color: #fff;
}
.dd-wrapper .dd-header span {
  margin-right: 20px;
}
.dd-wrapper .dd-header-title {
  font-weight: 300;
  margin: 2px 20px;
  margin-right: 30px;
}
.dd-wrapper .angle-down {
  color: #000;
  margin-right: 20px;
}
.dd-wrapper .dd-list {
  z-index: 10;
  position: absolute;
  width: 400px !important;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  font-weight: 500;
  padding: 15px 0;
  max-height: 215px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.dd-wrapper .dd-list-item {
  width: 100%;
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: default;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dd-wrapper .dd-list-item.selected {
  color: #fff;
  background-color: #274ca0 !important;
}
.dd-wrapper .dd-list-item:hover {
  color: #fff;
  background-color: #274ca0 !important;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader {
  margin: 2px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid #00dae8;
  border-right: 0.2em solid #00dae8;
  border-bottom: 0.2em solid #00dae8;
  border-left: 0.2em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6 !important;
}

.pia {
  border-left: 1px transparent !important;
  border-right: 2px transparent !important;
}

.btn-primary {
  color: #060f1e !important;
  background-color: #00dae7 !important;
  border-color: #00dae7 !important;
}

.btn-dark {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-outline {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
}

.btn-light {
  color: #fff !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

a {
  color: #ecf3fb !important;
  font-weight: 600;
}

a:hover {
  opacity: 0.9;
}

.lds-roller div:after {
  background: #00dae7;
}

.btn-group-lg > .btn,
.btn-lg {
  width: 100%;
  max-width: 300px;
}

.alert-danger .alert-link {
  color: #cb3e21 !important;
}

.alert-info .alert-link {
  color: black !important;
}

.alert-success .alert-link {
  color: rgb(38, 59, 44) !important;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 1.25rem;
  color: black !important;
}
